import { default as _91_46_46_46page_93plMEWPrPAfMeta } from "/vercel/path0/pages/[...page].vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as faqM4LN7jxnMuMeta } from "/vercel/path0/pages/faq.vue?macro=true";
import { default as galleryjDl5RbNP1cMeta } from "/vercel/path0/pages/gallery.vue?macro=true";
import { default as highlightsxqIDtGMklEMeta } from "/vercel/path0/pages/highlights.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91item_936E40VvcyMaMeta } from "/vercel/path0/pages/news/[item].vue?macro=true";
import { default as indexxeWP9UFz4RMeta } from "/vercel/path0/pages/news/index.vue?macro=true";
import { default as _91item_93VS61Z44hIgMeta } from "/vercel/path0/pages/packages/[item].vue?macro=true";
import { default as index1us1AaJFa5Meta } from "/vercel/path0/pages/packages/index.vue?macro=true";
import { default as privacykJ3SQnmrN4Meta } from "/vercel/path0/pages/privacy.vue?macro=true";
import { default as reviewsp0OUKSelxuMeta } from "/vercel/path0/pages/reviews.vue?macro=true";
import { default as _91item_93bm9CCcgfkyMeta } from "/vercel/path0/pages/rooms/[item].vue?macro=true";
import { default as indexshyPbhYt8IMeta } from "/vercel/path0/pages/rooms/index.vue?macro=true";
import { default as sitemapmtRscZaA8vMeta } from "/vercel/path0/pages/sitemap.vue?macro=true";
export default [
  {
    name: "page___en",
    path: "/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue")
  },
  {
    name: "page___nl",
    path: "/nl/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue")
  },
  {
    name: "page___de",
    path: "/de/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue")
  },
  {
    name: "page___fr",
    path: "/fr/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue")
  },
  {
    name: "page___es",
    path: "/es/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue")
  },
  {
    name: "page___it",
    path: "/it/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue")
  },
  {
    name: "contact___en",
    path: "/contact",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "contact___nl",
    path: "/nl/contact",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "contact___de",
    path: "/de/kontakt",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "contact___fr",
    path: "/fr/contact",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "contact___es",
    path: "/es/contacto",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "contact___it",
    path: "/it/contatto",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "faq___en",
    path: "/faq",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "faq___nl",
    path: "/nl/faq",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "faq___de",
    path: "/de/faq",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "faq___fr",
    path: "/fr/faq",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "faq___es",
    path: "/es/faq",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "faq___it",
    path: "/it/faq",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "gallery___en",
    path: "/gallery",
    component: () => import("/vercel/path0/pages/gallery.vue")
  },
  {
    name: "gallery___nl",
    path: "/nl/galerij",
    component: () => import("/vercel/path0/pages/gallery.vue")
  },
  {
    name: "gallery___de",
    path: "/de/galerie",
    component: () => import("/vercel/path0/pages/gallery.vue")
  },
  {
    name: "gallery___fr",
    path: "/fr/galerie",
    component: () => import("/vercel/path0/pages/gallery.vue")
  },
  {
    name: "gallery___es",
    path: "/es/galeria",
    component: () => import("/vercel/path0/pages/gallery.vue")
  },
  {
    name: "gallery___it",
    path: "/it/galleria",
    component: () => import("/vercel/path0/pages/gallery.vue")
  },
  {
    name: "highlights___en",
    path: "/highlights",
    component: () => import("/vercel/path0/pages/highlights.vue")
  },
  {
    name: "highlights___nl",
    path: "/nl/bezienswaardigheden",
    component: () => import("/vercel/path0/pages/highlights.vue")
  },
  {
    name: "highlights___de",
    path: "/de/sehenswurdigkeiten",
    component: () => import("/vercel/path0/pages/highlights.vue")
  },
  {
    name: "highlights___fr",
    path: "/fr/attractions",
    component: () => import("/vercel/path0/pages/highlights.vue")
  },
  {
    name: "highlights___es",
    path: "/es/atracciones",
    component: () => import("/vercel/path0/pages/highlights.vue")
  },
  {
    name: "highlights___it",
    path: "/it/attrazioni",
    component: () => import("/vercel/path0/pages/highlights.vue")
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___nl",
    path: "/nl",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___it",
    path: "/it",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "news-item___en",
    path: "/news/:item()",
    component: () => import("/vercel/path0/pages/news/[item].vue")
  },
  {
    name: "news-item___nl",
    path: "/nl/nieuws/:item()",
    component: () => import("/vercel/path0/pages/news/[item].vue")
  },
  {
    name: "news-item___de",
    path: "/de/nachrichten/:item()",
    component: () => import("/vercel/path0/pages/news/[item].vue")
  },
  {
    name: "news-item___fr",
    path: "/fr/actualites/:item()",
    component: () => import("/vercel/path0/pages/news/[item].vue")
  },
  {
    name: "news-item___es",
    path: "/es/noticias/:item()",
    component: () => import("/vercel/path0/pages/news/[item].vue")
  },
  {
    name: "news-item___it",
    path: "/it/notizie/:item()",
    component: () => import("/vercel/path0/pages/news/[item].vue")
  },
  {
    name: "news___en",
    path: "/news",
    component: () => import("/vercel/path0/pages/news/index.vue")
  },
  {
    name: "news___nl",
    path: "/nl/nieuws",
    component: () => import("/vercel/path0/pages/news/index.vue")
  },
  {
    name: "news___de",
    path: "/de/nachrichten",
    component: () => import("/vercel/path0/pages/news/index.vue")
  },
  {
    name: "news___fr",
    path: "/fr/actualites",
    component: () => import("/vercel/path0/pages/news/index.vue")
  },
  {
    name: "news___es",
    path: "/es/noticias",
    component: () => import("/vercel/path0/pages/news/index.vue")
  },
  {
    name: "news___it",
    path: "/it/notizie",
    component: () => import("/vercel/path0/pages/news/index.vue")
  },
  {
    name: "packages-item___en",
    path: "/special-offers/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue")
  },
  {
    name: "packages-item___nl",
    path: "/nl/arrangementen/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue")
  },
  {
    name: "packages-item___de",
    path: "/de/arrangements/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue")
  },
  {
    name: "packages-item___fr",
    path: "/fr/special-offers/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue")
  },
  {
    name: "packages-item___es",
    path: "/es/special-offers/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue")
  },
  {
    name: "packages-item___it",
    path: "/it/offerte-speciali/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue")
  },
  {
    name: "packages___en",
    path: "/special-offers",
    component: () => import("/vercel/path0/pages/packages/index.vue")
  },
  {
    name: "packages___nl",
    path: "/nl/arrangementen",
    component: () => import("/vercel/path0/pages/packages/index.vue")
  },
  {
    name: "packages___de",
    path: "/de/arrangements",
    component: () => import("/vercel/path0/pages/packages/index.vue")
  },
  {
    name: "packages___fr",
    path: "/fr/special-offers",
    component: () => import("/vercel/path0/pages/packages/index.vue")
  },
  {
    name: "packages___es",
    path: "/es/special-offers",
    component: () => import("/vercel/path0/pages/packages/index.vue")
  },
  {
    name: "packages___it",
    path: "/it/offerte-speciali",
    component: () => import("/vercel/path0/pages/packages/index.vue")
  },
  {
    name: "privacy___en",
    path: "/privacy-cookies",
    component: () => import("/vercel/path0/pages/privacy.vue")
  },
  {
    name: "privacy___nl",
    path: "/nl/privacy-cookies",
    component: () => import("/vercel/path0/pages/privacy.vue")
  },
  {
    name: "privacy___de",
    path: "/de/datenschutz-cookies",
    component: () => import("/vercel/path0/pages/privacy.vue")
  },
  {
    name: "privacy___fr",
    path: "/fr/confidentialite-cookies",
    component: () => import("/vercel/path0/pages/privacy.vue")
  },
  {
    name: "privacy___es",
    path: "/es/privacidad-cookies",
    component: () => import("/vercel/path0/pages/privacy.vue")
  },
  {
    name: "privacy___it",
    path: "/it/privacy-cookies",
    component: () => import("/vercel/path0/pages/privacy.vue")
  },
  {
    name: "reviews___en",
    path: "/reviews",
    component: () => import("/vercel/path0/pages/reviews.vue")
  },
  {
    name: "reviews___nl",
    path: "/nl/beoordelingen",
    component: () => import("/vercel/path0/pages/reviews.vue")
  },
  {
    name: "reviews___de",
    path: "/de/rezensionen",
    component: () => import("/vercel/path0/pages/reviews.vue")
  },
  {
    name: "reviews___fr",
    path: "/fr/revues",
    component: () => import("/vercel/path0/pages/reviews.vue")
  },
  {
    name: "reviews___es",
    path: "/es/resenas",
    component: () => import("/vercel/path0/pages/reviews.vue")
  },
  {
    name: "reviews___it",
    path: "/it/recensioni",
    component: () => import("/vercel/path0/pages/reviews.vue")
  },
  {
    name: "rooms-item___en",
    path: "/rooms/:item()",
    component: () => import("/vercel/path0/pages/rooms/[item].vue")
  },
  {
    name: "rooms-item___nl",
    path: "/nl/kamers/:item()",
    component: () => import("/vercel/path0/pages/rooms/[item].vue")
  },
  {
    name: "rooms-item___de",
    path: "/de/zimmer/:item()",
    component: () => import("/vercel/path0/pages/rooms/[item].vue")
  },
  {
    name: "rooms-item___fr",
    path: "/fr/chambres/:item()",
    component: () => import("/vercel/path0/pages/rooms/[item].vue")
  },
  {
    name: "rooms-item___es",
    path: "/es/habitaciones/:item()",
    component: () => import("/vercel/path0/pages/rooms/[item].vue")
  },
  {
    name: "rooms-item___it",
    path: "/it/camere/:item()",
    component: () => import("/vercel/path0/pages/rooms/[item].vue")
  },
  {
    name: "rooms___en",
    path: "/rooms",
    component: () => import("/vercel/path0/pages/rooms/index.vue")
  },
  {
    name: "rooms___nl",
    path: "/nl/kamers",
    component: () => import("/vercel/path0/pages/rooms/index.vue")
  },
  {
    name: "rooms___de",
    path: "/de/zimmer",
    component: () => import("/vercel/path0/pages/rooms/index.vue")
  },
  {
    name: "rooms___fr",
    path: "/fr/chambres",
    component: () => import("/vercel/path0/pages/rooms/index.vue")
  },
  {
    name: "rooms___es",
    path: "/es/habitaciones",
    component: () => import("/vercel/path0/pages/rooms/index.vue")
  },
  {
    name: "rooms___it",
    path: "/it/camere",
    component: () => import("/vercel/path0/pages/rooms/index.vue")
  },
  {
    name: "sitemap___en",
    path: "/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  },
  {
    name: "sitemap___nl",
    path: "/nl/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  },
  {
    name: "sitemap___de",
    path: "/de/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  },
  {
    name: "sitemap___fr",
    path: "/fr/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  },
  {
    name: "sitemap___es",
    path: "/es/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  },
  {
    name: "sitemap___it",
    path: "/it/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  }
]